.platform-grid{
    color: blue;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.platform-link{
    text-decoration: none;
}

.platform-grid-button{
    min-width: 250px;
    min-height: 80px;
}

.p-card-content{
    text-align: center;
}

.platform-grid .p-button-icon{
    font-size: 1.8em !important;
}

.p-galleria .p-galleria-thumbnail-container {
    background: none;
}

.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev, .p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
    color: black;
}