.main {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: hidden;
}

.main_with_side_bar {
    width: 100%;
    height: 100%;
    background: #f4f3f3;
    overflow-y: auto;
    display: flex;
    padding-top: 64px;
}

.main_content {
    width: calc(100% - 215px);
    height: 100%;
    display: flex;
    position: relative;
}

.content-container {
    margin: 22px;
    background-color: #FFFFFF;
    padding: 2px;
    width: 100%;
    border-radius: 10px;
    font-family: Verlag;
    overflow-y: auto;

}

.top {
    position: absolute;
    width: 100%;
    height: 64px;
    background-color: white;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
    padding: 12px 26px;
    box-sizing: border-box;
    webkit-filter: drop-shadow(0 2px 10px rgba(0, 0, 0, .25));
    filter: drop-shadow(0 2px 10px rgba(0, 0, 0, .25));
    z-index: 92;
}


.login {
    position: relative;
    top: 100px;
    text-align: center;
}

.side {
    min-width: 215px;
    left: 0px;
    background: #FFFFFF;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.1);
}

.loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(196, 196, 196, 0.51);
    padding-left: 47%;
    padding-top: 20%;
    z-index: 1000;
}

.errorMsg {
    font-family: Verlag;
    font-size: 18px;
    color: #cc0000;
    margin-bottom: 12px;
}

.successMsg {
    font-family: Verlag;
    font-size: 18px;
    color: #00cc00;
    margin-bottom: 12px;
}