.div-flexbox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding-right: 30px;
}

.div-flexbox .p-button {
  margin-left: 10px;
}

a:link {
  text-decoration: none;
}

#analytics-container .p-component {
  height: 100%;
}

#analytics-container .p-tabview-panels {
  height: calc(100% - 58px)
}

#analytics-container .p-tabview-panel {
  height: 100%;
}