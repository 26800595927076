.ouro {
  position: relative;
  display: inline-block;
  height: 55px;
  width: 55px;
  margin: 1em;
  border-radius: 50%;
  background: none repeat scroll 0 0 #F2F2F2;
  overflow: hidden;
}

.ouro:after {
  content: "";
  position: absolute;
  top: 8.5px;
  left: 9px;
  display: block;
  height: 38px;
  width: 38px;
  background: none repeat scroll 0 0 #F2F2F2;
  border-radius: 50%;
}

.ouro>span {
  position: absolute;
  height: 100%;
  width: 50%;
  overflow: hidden;
}

.left {
  left: 0
}

.right {
  left: 50%
}

.anim {
  position: absolute;
  left: 100%;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 999px;
  background: none repeat scroll 0 0 #077186;
  opacity: 0.8;
  -webkit-animation: ui-spinner-rotate-left 3s infinite;
  animation: ui-spinner-rotate-left 3s infinite;
  -webkit-transform-origin: 0 50% 0;
  transform-origin: 0 50% 0;
}

.left .anim {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.right .anim {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  left: -100%;
  -webkit-transform-origin: 100% 50% 0;
  transform-origin: 100% 50% 0;
}

/* v3 */
.ouro3 .anim {
  -webkit-animation-delay: 0s;
  -webkit-animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-delay: 0s;
  animation-duration: 3s;
  animation-timing-function: linear;
}

.ouro3 .right .anim {
  -webkit-animation-name: ui-spinner-rotate-right;
  -webkit-animation-delay: 0;
  -webkit-animation-delay: 1.5s;
  animation-name: ui-spinner-rotate-right;
  animation-delay: 0;
  animation-delay: 1.5s;
}

@keyframes ui-spinner-rotate-right {
  0% {
    transform: rotate(0deg)
  }

  25% {
    transform: rotate(180deg)
  }

  50% {
    transform: rotate(180deg)
  }

  75% {
    transform: rotate(360deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes ui-spinner-rotate-left {
  0% {
    transform: rotate(0deg)
  }

  25% {
    transform: rotate(0deg)
  }

  50% {
    transform: rotate(180deg)
  }

  75% {
    transform: rotate(180deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes ui-spinner-rotate-right {
  0% {
    -webkit-transform: rotate(0deg)
  }

  25% {
    -webkit-transform: rotate(180deg)
  }

  50% {
    -webkit-transform: rotate(180deg)
  }

  75% {
    -webkit-transform: rotate(360deg)
  }

  100% {
    -webkit-transform: rotate(360deg)
  }
}

@-webkit-keyframes ui-spinner-rotate-left {
  0% {
    -webkit-transform: rotate(0deg)
  }

  25% {
    -webkit-transform: rotate(0deg)
  }

  50% {
    -webkit-transform: rotate(180deg)
  }

  75% {
    -webkit-transform: rotate(180deg)
  }

  100% {
    -webkit-transform: rotate(360deg)
  }
}

/* presentation styles */
.block {
  display: table-cell;
  vertical-align: middle
}