.climate_grey_out {
    width: 100%;
    background: rgba(196, 196, 196, 0.51);
    text-align: center;
}

.esg-form .card {
    min-width: 800px;
    max-width: 1040px;
}

.esg-form .card .form {
    margin-top: 2rem;
}

.esg-form .card .field {
    margin-bottom: 1rem;
}

.p-button {
    margin-right: 0.5rem;
}
