@font-face {
  font-family: Verlag;
  src: url('../../assets/fonts/Verlag-Book.otf') format('OpenType');
  font-weight: normal;
  font-style: normal;
}

.container2 {
  text-align: center;
  width: 100%;
  background-color: white;
  padding-right: 25%;
  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 25%;
}

.logOut {
  width: 100%;
  color: #111A3F;
  letter-spacing: 1px;
  font-family: Verlag;
  font-size: 40px;
  line-height: 35px;
}