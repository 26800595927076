@font-face {
  font-family: Verlag;
  src: url('../../assets/fonts/Verlag-Book.otf') format('OpenType');
  font-weight: normal;
  font-style: normal;
}

.space {
  margin: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.container {
  position: absolute;
  text-align: center;
  left: 28.4%;
  right: 28.4%;
  top: 260.7%;
  bottom: 50.8%;
}

input {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid lightgrey;
  padding: 10px;
  outline: none
}

.input {
  text-align: left;
  width: 35%;
  color: #111A3F;
  letter-spacing: 1px;
  font-family: Verlag;
  font-size: 25px;
  line-height: 48px;
  outline: none
}

input:focus::-webkit-input-placeholder {
  color: #111A3F;
}

.title {
  color: #111A3F;
  letter-spacing: 1px;
  font-family: Verlag;
  font-size: 40px;
  line-height: 48px;
}

.confirm {
  position: "absolute";
  background: #0F1942;
  color: white;
  width: 150px;
  height: 40px;
  outline: none;
  font-family: Verlag;
  font-size: 20px;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin: 5px;
}

.confirm:hover {
  transform: translateY(-3px);
}

.label {
  color: white;
  letter-spacing: 1px;
  font-family: Verlag;
  font-size: 20px;
}

/* .button{
  position: "absolute";
  background: transparent;
  color:#0F1942;
  width: 150px;
  font-family: Verlag;
  font-size: 20px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
  border: none;
}
.button:hover{
  transform: translateY(-3px);
} */


.errorInput {
  border-bottom: 1px solid #cc0000;
}