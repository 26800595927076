@font-face {
    font-family: Verlag;
    src: url('../../assets/fonts/Verlag-Book.otf') format('OpenType');
    font-weight: normal;
    font-style: normal;
}

.ag-theme-alpine {
    --ag-odd-row-background-color: #f0f4f5;

    --ag-header-column-separator-display: block;
    --ag-header-column-separator-height: 50%;
    --ag-header-column-separator-width: 2px;
}

.admin-container {
    background: #FFFFFF;
    border-radius: 10px;
    height: 100%;
}