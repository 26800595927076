@font-face {
  font-family: Verlag;
  src: url('../../assets/fonts/Verlag-Book.otf') format('OpenType');
  font-weight: normal;
  font-style: normal;
}

ul {
  margin-block-start: 0 !important;
}

.nav-menu {
  display: flex;
  flex-direction: column;

}

.nav-menu-item {
  position: relative;
  height: 70px;
  top: 0;
  background: #FFFFFF;
  box-shadow: inset 0px 6px 6px rgb(0 0 0 / 10%);
  text-align: center;
}

.nav-menu-item:hover {
  background: rgba(7, 113, 134, .14);
}


.nav-menu-app-name {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-menu-button {
  height: 100%;
  font-family: Verlag;
  font-size: 20px;
  cursor: pointer;
  border: none;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 22px;
  height: 22px;
  margin: 0px 10px;
}

.home {
  width: 22px;
  height: 22px;
  align-items: center;
}

/* unvisited link */
.nav-menu-button > a:link {
  color: #0F1942;
}

/* visited link */
.nav-menu-button > a:visited {
  color: #0F1942;
}

/* mouse over link */
.nav-menu-button > a:hover {
  color: #0F1942;
}

/* selected link */
.nav-menu-button > a:active {
  color: #0F1942;
}

.logo2 {
  margin-top: 0;
  height: 35px;
  top: calc(50% - 10px);
}

.nav-menu-link {
  text-decoration: none;
  display: flex;
  justify-content: center;
  height: 100%;
  align-content: center;
  align-items: center;
}

.nav-menu-text {
  min-width: 140px;
}

.active-link {
  background-color: rgba(7, 113, 134, .14);
}

.nav-menu-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.user-container {
  margin-top: auto;
  display: flex;
  flex-direction: column;
}

.user-item {
  width: 100%;
  padding: 5px;
}

.p-chip.user-chip {
  color: black;
  opacity: 85%;
  font-size: 1em;
  width: 100%;
}