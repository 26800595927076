.upload {
    /* position: absolute;
    left: 270px;
    right: 20px;
    top: 82px;
    bottom: 3%; */
    margin: 22px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.location {
    position: relative;
    /* left: 10%;
    right: 40%;
    top: 15%;
    bottom: 0;
    width: 80%; */
    background: transparent;
    border-radius: 10px;
    /* padding: 20px; */
    padding-top: 60px;
    text-align: center;
}

.icon2 {
    position: relative;
    width: 250px;
    height: 27%;
}

.box2 {
    position: relative;
    top: 15%;
    background: #077186;
    border-radius: 30px;
    padding: 10px 2px 10px 2px;
    text-align: center;
    transition: transform 0.3s ease;
}

.box2:hover {
    transform: translateY(-3px);
}

.box3 {
    position: relative;
    width: 220px;
    height: 30px;
    top: 15%;
    left: 42%;
    right: 45%;
    text-align: center;
    background-color: transparent;
}

.text2 {
    background: transparent;
    position: relative;
    background: transparent;
    color: white;
    width: 230px;
    font-family: Verlag;
    font-size: 20px;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.icon3 {
    width: 22px;
    height: 22px;
    padding: 0 12px 0 35px;
}

.text6 {
    /* padding-top: 2%; */
    font-family: Verlag;
    font-size: 20px;
    color: #0F1942;
    display: inline-flex;
}

.grey-out2 {
    position: absolute;
    /* top: 0; */
    width: 100%;
    /* height: 14%; */
    background: rgba(196, 196, 196, 0.51);
    /* padding: 10px 30px 20px 25%; */
    /* padding: 10px 0 10px 35px; */
    /* padding: 5px 15%; */
    /* padding: 5%; */
    text-align: center;
}

.download {
    position: relative;
    background: #0F1942;
    color: white;
    width: 170px;
    height: 40px;
    outline: none;
    font-family: Verlag;
    cursor: pointer;
    font-size: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease;
    /* margin-left: 40px; */
}

.download:hover {
    transform: translateY(-3px);
}

.icon-download {
    width: 18px;
    height: 18px;
    padding: 0 12px 0 0px;
}

.button {
    position: "absolute";
    background: transparent;
    color: #0F1942;
    width: 150px;
    font-family: Verlag;
    font-size: 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
    border: none;
}

.button:hover {
    transform: translateY(-3px);
}

.css-f91nen .MuiButtonGroup-grouped:not(:last-of-type) {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 1px solid #0F1942 !important;
    border-top-color: #0F1942 !important;
    border-bottom-color: #0F1942 !important;
    border-left-color: #0F1942 !important;
    padding: 5px 10px !important;
}

.css-1lpukdo {
    background-color: #0F1942 !important;
    box-shadow: 0px;
}

.pad {
    padding: 0px 20px;
    display: inline;
}

.upload-form-div {
    position: relative;
    display: flex;
    flex-direction: row;
    /* top: 7%;
    left: 42%;
    right: 45%; */
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    background-color: transparent;
}

.margin-top-10 {
    margin-top: 10px;
}

.error-msg-upload {
    position: relative;
    top: 15%;
    left: 42%;
    right: 45%;
}