@font-face {
    font-family: Verlag;
    src: url('../../assets/fonts/Verlag-Book.otf') format('OpenType');
    font-weight: normal;
    font-style: normal;
}

.track {
    /* position: absolute;
    left: 270px;
    right: 20px;
    top: 82px;
    bottom: 3%; */
    /* background: #FFFFFF; */
    padding: 22px;
    width: 100%;
    border-radius: 10px;
    font-family: Verlag;
    overflow-y: auto;
}

.box4 {
    position: absolute;
    left: 40px;
    top: 200px;
    background: #FFFFFF;
    border-radius: 10px;
    font-family: Verlag;
    padding: 10px 30px 10px 30px;
}

.text4 {
    font-family: Verlag;
    font-size: 35px;
    color: #0F1942;
}

.text_title {
    font-family: Verlag;
    font-size: 35px;
    color: #0F1942;
    padding: 10px 40px 10px 20px;

}

.text5 {
    font-family: Verlag;
    font-size: 20px;
    color: #0F1942;
}

.tablelayout {
    position: absolute;
    background-color: transparent;
    top: 5%;
    left: 5%;
    right: 5%;
}

 table {
    font-family: Verlag;
    font-size: 18px;
    border-collapse: collapse;
    width: 100%;
}

table td {
    border: transparent;
    padding: 10px;
}

table th {
    border: transparent;
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
}

table tr:nth-child(even) {
    background-color: rgba(7, 113, 134, 0.08) !important;
}

table tr:hover {
    background-color: #ddd;
}

table th {
    font-family: Verlag !important;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
    text-align: left;
    background-color: transparent;
    font-size: 20px;
    color: #0F1942;
}

table th:first-child {
    left: 0;
    z-index: 100;
}
/*
.MuiTableCell-root {
    padding-left: 50px !important;
}

.MuiToolbar-root {
    padding-top: 20px;
} */